import * as React from "react";

function NavigationLink({ label, href, className = "" }) {
  return (
    <a 
      href={href}
      className={`hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 ${className}`}
      role="menuitem"
    >
      {label}
    </a>
  );
}

export default NavigationLink;