import React from 'react';

function ImageGrid() {
  return (
    <div className="flex flex-col w-[46%] max-md:ml-0 max-md:w-full">
      <div className="self-stretch my-auto max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow">
              <div className="flex shrink-0 rounded-3xl aspect-square bg-zinc-300 h-[283px] w-[284px]" />
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/24323b64a1c924cd07574765399550d3bc7f4bbfbf30aaaa3e9eaf34210979b8?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
                alt=""
                className="object-contain w-full aspect-square rounded-[1000px]"
              />
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/abb12e4fbe8e809fc9d889b711e12773d901ad90ae51db8998c60ea64426018f?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
                alt=""
                className="object-contain z-10 w-full aspect-square rounded-[500px]"
              />
              <div className="flex flex-col items-center px-8 pt-4 bg-pink-600 rounded-3xl aspect-square w-[283px] max-md:px-5 max-md:mr-1">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f88e340f51954932570138381f8dc6f73c9b98e89f5aaef224d1770c32a033ac?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
                  alt=""
                  className="object-contain w-full aspect-[0.78]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageGrid;