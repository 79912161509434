import * as React from "react";
import { BusinessCTA } from "./BusinessCTA";
import { BusinessImage } from "./BusinessImage";

function BusinessHero() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#03045E] to-[#0608C4] rounded-[40px] overflow-hidden m-8 md:m-16">
      <div className="flex flex-col items-center px-10 pt-20 w-full max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col items-center ml-3 max-w-full w-[756px] max-md:w-full">
          <div className="text-7xl font-bold text-white w-[440px] max-md:max-w-full max-md:text-4xl">
            Business in your pocket
          </div>
          <BusinessCTA />
          <BusinessImage />
        </div>
      </div>
    </div>
  );
}

export default BusinessHero;
