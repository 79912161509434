import * as React from "react";

export function StartEarningText() {
  return (
    <>
      <h1 className="mr-12 text-5xl font-bold text-black max-md:mr-2.5 max-md:max-w-full max-md:text-4xl">
        Start earning on Earn today <br />
        Your skills deserve it!
      </h1>
      <p className="mt-9 text-3xl leading-10 text-stone-500 max-md:max-w-full">
        At Earn, we empower freelancers to take control of their careers
        and clients to access world-class talent. Whether you're just
        starting or a seasoned pro, Earn makes it easy to land gigs,
        deliver results, and grow your business
      </p>
    </>
  );
}