import React, { useState } from "react";
import CreateAccount from "./CreateAccount";
import ProfileSetup from "./ProfileSetup";
import ConfirmEmail from "./ConfirmEmail";
import StartExploring from "./StartExploring";
import SellingOrBuying from "../Login/SellingorBuying";
const LoginFlow = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    username: "",
    role: "",
  });

  const handleNext = (data) => {
    setUserData((prev) => ({ ...prev, ...data }));
    setCurrentStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  return (
    <div>
      {currentStep === 1 && <CreateAccount onNext={handleNext} />}
      {currentStep === 2 && (
        <ProfileSetup
          userData={userData}
          onNext={handleNext}
          onBack={handleBack}
        />
      )}
      {currentStep === 3 && (
        <ConfirmEmail
          userData={userData}
          onNext={handleNext}
          onBack={handleBack}
        />
      )}
      {currentStep === 4 && (
        <SellingOrBuying
          userData={userData}
          onNext={handleNext}
          onBack={handleBack}
        />
      )}
      {currentStep === 5 && <StartExploring userData={userData} />}
    </div>
  );
};

export default LoginFlow;
