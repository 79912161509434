import * as React from "react";

export function ActionButton() {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <button 
      className={`self-center px-11 py-5 mt-16 max-w-full text-2xl font-medium text-black bg-white rounded-[59px] w-[222px] max-md:px-5 max-md:mt-10 transition-transform duration-300 ${isHovered ? 'scale-105' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => window.location.href = '/contact'}
      tabIndex={0}
      aria-label="Work with us"
    >
      Work with us
    </button>
  );
}
