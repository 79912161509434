import * as React from "react";
import { HeroContent } from "./HeroContent";

export function CallToActionSection() {
  return (
    <section
      className="flex flex-col text-center rounded-none p-8 md:p-16"
      aria-label="Call to action section"
    >
      <div className="flex flex-col justify-center items-center px-20 py-14 w-full bg-blue-950 rounded-[40px] max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col w-full max-w-[1164px] max-md:max-w-full">
          <HeroContent />
        </div>
      </div>
    </section>
  );
}
