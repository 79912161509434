import * as React from "react";
import {ServiceCard} from "./ServiceCard";

const services = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/7948503be3fd13f97e469a6ffa51b8c69bd4ba8a4f5a0a2ce16ff18d0233fcb3?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff",
    iconRight: "https://cdn.builder.io/api/v1/image/assets/TEMP/4a6d20b2cef6254ad5e498199a2045bde1104b21e63360ab2a4e0ff3a1aaeb52?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff",
    title: "Graphic Design",
    description: "Unleash your brand's creativity with stunning visual designs tailored to your needs. From logos to full brand identities, our graphic designers turn ideas into eye-catching reality"
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6c0a0217ec5a0709570e0730db110f3f5596477a721ccefd5a884fc07387b8b4?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff",
    iconRight: "https://cdn.builder.io/api/v1/image/assets/TEMP/4a6d20b2cef6254ad5e498199a2045bde1104b21e63360ab2a4e0ff3a1aaeb52?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff",
    title: "Digital Marketing",
    description: "Reach your target audience and boost your online presence with our expert digital marketers. From SEO to social media campaigns, we help you grow and succeed in the digital space"
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6c0a0217ec5a0709570e0730db110f3f5596477a721ccefd5a884fc07387b8b4?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff",
    iconRight: "https://cdn.builder.io/api/v1/image/assets/TEMP/4a6d20b2cef6254ad5e498199a2045bde1104b21e63360ab2a4e0ff3a1aaeb52?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff",
    title: "Writing & Translation",
    description: "Elevate your content with our professional writers and translators. Whether you need engaging articles, precise translations, or captivating copy, we've got you covered"
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/9a9cb08cd2f41adfb9a15ce13ef6d067dc89fcf330c6febaf93144f2cfcc4106?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff",
    iconRight: "https://cdn.builder.io/api/v1/image/assets/TEMP/e34ccf5c3e7e6b99416d5c77c542da6c191909e783b897c8aa64626e6c45ba1e?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff",
    title: "Music & Audio",
    description: "Set the tone with top-notch music and audio services. From original compositions to sound design and voiceovers, our experts create the perfect audio experience"
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cdacaa652ab884fc72a9c4d162caacab6723dfc9b9371dba303c61a6fdce4218?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff",
    iconRight: "https://cdn.builder.io/api/v1/image/assets/TEMP/e34ccf5c3e7e6b99416d5c77c542da6c191909e783b897c8aa64626e6c45ba1e?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff",
    title: "Photography",
    description: "Capture the moment with our skilled photographers. Perfect for events, product shoots, and portraits, our photographers bring your vision to life through the lens"
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8743a906d1c749774fe0ab606011939ca99c9167f6b0d21d40de844ed59e4f0a?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff",
    iconRight: "https://cdn.builder.io/api/v1/image/assets/TEMP/e34ccf5c3e7e6b99416d5c77c542da6c191909e783b897c8aa64626e6c45ba1e?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff",
    title: "Programming and Tech",
    description: "Solve your tech challenges with our experienced programmers and tech experts. Whether it's web development, app creation, or IT support, we provide innovative solutions"
  }
];

export default function ServicesSection() {
  return (
    <div className="flex flex-col rounded-none">
      <div className="flex flex-col items-center px-12 pt-20 pb-12 w-full bg-blue-950 rounded-[39px] max-md:px-5 max-md:max-w-full">
        <div className="text-5xl font-bold text-center text-white max-md:text-4xl">
          Talent & Services
        </div>
        <div className="mt-8 text-xl font-bold text-center text-white max-md:max-w-full">
          Welcome to Earn, the freelance platform that connects your skills to
          endless opportunities! Whether you're a designer, developer, writer,
          or consultant, Earn is the space where creativity meets demand, and
          passion turns into profit.
        </div>
        <div className="self-stretch mt-16 max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            {services.slice(0, 3).map((service, index) => (
              <div key={index} className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                <ServiceCard {...service} />
              </div>
            ))}
          </div>
        </div>
        <div className="self-stretch mt-8 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            {services.slice(3).map((service, index) => (
              <div key={index} className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                <ServiceCard {...service} />
              </div>
            ))}
          </div>
        </div>
        <button 
          className="mt-11 text-4xl font-bold text-center text-white max-md:mt-10"
          tabIndex={0}
          aria-label="View all services"
        >
          See all
        </button>
      </div>
    </div>
  );
}