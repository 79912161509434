import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Smile from "../../asserts/smile.png";

const StartExploring = ({ userData }) => {
  const handleStartExploring = () => {
    window.location.href = "/dashboard";
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        bgcolor: "#FFFFFF",
        px: 6,
      }}
    >
      <Box
        component="img"
        src={Smile}
        alt="Smiley Icon"
        sx={{
          width: "64px",
          height: "64px",
          mb: 4,
        }}
      />

      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          mb: 2,
          textAlign: "center",
        }}
      >
        {` this is the start of your journey`}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 4,
          color: "#666",
          textAlign: "center",
        }}
      >
        We’re happy to have you onboard! Feel free to start exploring the
        services and creators on our platform.
      </Typography>

      <Button
        variant="contained"
        sx={{
          bgcolor: "#D3F26A",
          color: "#333",
          textTransform: "none",
          fontWeight: "bold",
          borderRadius: "12px",
          px: 4,
          py: 1.5,
          "&:hover": {
            bgcolor: "#c0e55a",
          },
        }}
        onClick={handleStartExploring}
      >
        Start Exploring
      </Button>
    </Box>
  );
};

export default StartExploring;
