import * as React from "react";

export const SocialButton = ({ icon, text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="flex gap-8 px-5 py-2.5 text-base font-bold text-center text-black bg-white rounded-lg border-2 border-solid border-black border-opacity-20 w-full items-center"
    >
      <img loading="lazy" src={icon} alt="" className="object-contain shrink-0 w-8 aspect-square" />
      <span className="my-auto">{text}</span>
    </button>
  );
};