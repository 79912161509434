import * as React from "react";

export function HeroSection() {
  return (
    <section
      className="mt-28 ml-3 text-center max-md:mt-10 max-sm:mt-6 max-sm:ml-0 max-sm:px-4"
      aria-labelledby="hero-heading"
    >
      <h1
        id="hero-heading"
        className="text-7xl font-bold text-black leading-tight max-md:text-4xl max-md:max-w-xl max-md:mx-auto max-sm:text-3xl"
      >
        Talent, Working, Earning
      </h1>
      <p
        className="mt-9 ml-4 text-xl font-medium text-black max-md:max-w-xl max-md:mx-auto max-md:mt-6 max-md:text-lg max-sm:text-base"
      >
        The freelance platform that connects your skills to endless opportunities
      </p>
    </section>
  );
}
