import * as React from "react";

export function SetupCard({ icon, title, description, action }) {
  return (
    <div className="flex flex-wrap gap-5 justify-between self-stretch px-9 py-7 w-full rounded-3xl border border-solid border-black border-opacity-20 max-md:px-5 max-md:max-w-full">
      <div className="flex gap-6 text-black">
        <img loading="lazy" src={icon} alt="" className="object-contain shrink-0 self-start mt-2.5 aspect-square w-[34px]" />
        <div className="flex flex-col grow shrink-0 basis-0 w-fit">
          <div className="self-start text-base font-bold">{title}</div>
          <div className="mt-1.5 text-base">{description}</div>
        </div>
      </div>
      <div className="my-auto text-base font-bold text-black text-opacity-50">
        {action}
      </div>
    </div>
  );
}