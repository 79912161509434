import React from 'react';

const filters = [
  { name: "Category", className: "whitespace-nowrap" },
  { name: "Seller Info", className: "" },
  { name: "Budget", className: "whitespace-nowrap" },
  { name: "Delivery Time", className: "grow" },
  { name: "Availability", className: "whitespace-nowrap" }
];

export default function FilterSection() {
  return (
    <div className="flex flex-wrap gap-5 items-start self-start mt-7 text-xl text-neutral-900">
      {filters.map((filter, index) => (
        <div key={index} className="flex gap-4 px-8 py-4 bg-white rounded-xl border border-solid border-black border-opacity-20 max-md:px-5">
          <div className={filter.className}>{filter.name}</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f7b48b1148dbe43b7f724bb4c8f311c7a47baa45d45c9b9199f7206592968e89?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
            alt=""
            className="object-contain shrink-0 self-start w-5 aspect-square"
          />
        </div>
      ))}
    </div>
  );
}