// src/components/Profile/MarketplaceHeader.js

import React from "react";
import { NavigationLink } from "./NavigationLink";
import { ProfileSection } from "./ProfileSection";
import { navigationLinks } from "./NavigationData";
import { useAuth } from "../../context/AuthContext";

export default function MarketplaceHeader() {
  const { authState, signOut } = useAuth();
  const { isAuthenticated, user } = authState;

  return (
    <div className="flex flex-col rounded-none">
      <div className="flex flex-col items-center py-6 w-full bg-white max-md:max-w-full">
        <div className="flex flex-wrap gap-10 items-center w-full max-w-[1378px] max-md:max-w-full">
          {/* Logo */}
          <div className="grow self-stretch my-auto text-3xl font-black text-orange-600">
            EARN
          </div>

          {/* Category Badge */}
          <div className="self-stretch px-6 py-3.5 text-xl font-bold text-white bg-gray-200 rounded-xl border border-solid border-white border-opacity-20 max-md:px-5 max-md:max-w-full">
            Graphic Design
          </div>

          {/* Notification Icons */}
          <div className="flex gap-6 self-stretch my-auto">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f609745a1f6a6b9649572a37a1100fab4126ad54a38bad66616ac09b1c486a09?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
              className="object-contain shrink-0 w-12 aspect-square"
              alt="Notification Bell"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ed56ebdeb3122da67b52b936aab4a7363b60ae5f3ec965ba7bf2f7f2e7c924ae?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
              className="object-contain shrink-0 w-12 aspect-square"
              alt="Messages Icon"
            />
          </div>

          {/* Profile Section */}
          {isAuthenticated ? (
            <ProfileSection user={user} onSignOut={signOut} />
          ) : (
            <div className="flex gap-4">
              <button
                
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
              >
                Sign In
              </button>
              <button
                
                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition"
              >
                Sign Up
              </button>
            </div>
          )}
        </div>

        {/* Divider */}
        <div className="flex self-stretch mt-5 w-full bg-black bg-opacity-10 min-h-[2px] max-md:max-w-full" />

        {/* Navigation Links */}
        <div className="flex flex-wrap gap-10 mt-5 w-full max-w-[1371px] max-md:max-w-full">
          {navigationLinks.map((link) => (
            <NavigationLink key={link.id} title={link.title} />
          ))}
        </div>
      </div>
    </div>
  );
}
