import Footer from "../components/Footer";
import {CallToActionSection} from "../components/LandingPage/CallToAction";
import FreelanceSolutionSection from "../components/LandingPage/FreelanceSolution";
import Header from "../components/LandingPage/Header";
import {HeroSection} from "../components/LandingPage/HeroSection";
import ServicesSection from "../components/LandingPage/ServicesSection";
import {StartEarningSection} from "../components/LandingPage/StartEarning";
import { StatsSection } from "../components/LandingPage/StatsSection";
import WhyChooseEarn from "../components/LandingPage/WhyChooseEarn";
import BusinessHero from '../components/LandingPage/BusinessHero';

const Landing = () => {
  return (
    <div>
      <Header />
       <HeroSection />
      <StatsSection />
      <ServicesSection />
      <WhyChooseEarn />
      <StartEarningSection />
      <FreelanceSolutionSection />
      <BusinessHero />
      <CallToActionSection />
     
      <Footer />
    </div>
  );
};

export default Landing;
