import React from 'react';
import FeatureCard from './FeatureCard';
import ImageGrid from './ImageGrid';

const features = [
  {
    title: "Find Your Match",
    description: "Connect with clients who value your expertise",
    highlighted: false
  },
  {
    title: "Boost Your Income",
    description: "Unlock projects tailored to your skills, anytime, anywhere",
    highlighted: true
  },
  {
    title: "Build Your Brand",
    description: "Showcase your portfolio and grow your professional reputation",
    highlighted: false
  },
  {
    title: "Safe & Secure",
    description: "From contracts to payments, we've got you covered",
    highlighted: true
  },
  {
    title: "Thrive on Flexibility",
    description: "Work your way, on your terms, at your pace",
    highlighted: false
  }
];

function WhyChooseEarn() {
  return (
    <div className="p-6 md:p-10 rounded-none"> {/* Added padding here */}
      <div className="flex gap-5 max-md:flex-col">
        <ImageGrid />
        <div className="flex flex-col ml-5 w-[54%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col w-full max-md:mt-9 max-md:max-w-full">
            <div className="flex flex-col pl-6 w-full max-md:pl-5 max-md:max-w-full">
              <h1 className="self-start text-5xl font-bold text-center text-black max-md:text-4xl">
                Why Choose Earn?
              </h1>
              {features.map((feature, index) => (
                <FeatureCard
                  key={index}
                  title={feature.title}
                  description={feature.description}
                  highlighted={feature.highlighted}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseEarn;
