import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/**
 * AuthButton Component
 *
 * A versatile button component supporting different variants, loading states, and accessibility features.
 *
 * @param {Object} props - Component props.
 * @param {string} [props.variant="primary"] - The variant of the button (`primary`, `text`).
 * @param {string} [props.label="Button"] - The text displayed on the button.
 * @param {string} [props.className=""] - Additional custom classes for styling.
 * @param {function} [props.onClick=() => {}] - Click event handler.
 * @param {boolean} [props.isLoading=false] - Indicates if the button is in a loading state.
 * @param {string} [props.ariaLabel] - Accessibility label, defaults to `label` if not provided.
 * @param {boolean} [props.disabled=false] - Disables the button when `true`.
 * @param {Object} [props.rest] - Additional props to pass to the button element.
 * @returns {JSX.Element} The rendered button component.
 */
function AuthButton({
  variant = "primary",
  label = "Button",
  className = "",
  onClick = () => {},
  isLoading = false,
  ariaLabel,
  disabled = false,
  ...rest
}) {
  // Base styles common to all button variants
  const baseStyles =
    "focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 transition-colors duration-200";

  // Styles specific to each variant
  const variantStyles = {
    text: "text-blue-600 hover:text-blue-800",
    primary:
      "px-6 py-3 text-white bg-blue-600 rounded-full hover:bg-blue-700 max-md:px-4",
  };

  // Determine spinner color based on variant
  const spinnerColor = variant === "primary" ? "text-white" : "text-blue-600";

  return (
    <button
      className={clsx(
        baseStyles,
        variantStyles[variant],
        className,
        {
          "opacity-50 cursor-not-allowed": disabled || isLoading,
        }
      )}
      type="button"
      onClick={onClick}
      aria-label={ariaLabel || label}
      disabled={disabled || isLoading}
      {...rest}
    >
      {isLoading ? (
        <span className="inline-flex items-center justify-center">
          <svg
            className={`w-5 h-5 ${spinnerColor} animate-spin`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </span>
      ) : (
        label
      )}
    </button>
  );
}

// Define prop types for better type checking and documentation
AuthButton.propTypes = {
  variant: PropTypes.oneOf(["primary", "text"]),
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

export default AuthButton;
