import * as React from "react";

export const StatsCard = ({ number, description, className }) => {
  return (
    <div className={`flex flex-col px-7 py-16 text-center ${className}`}>
      <div className="text-5xl font-extrabold max-md:mr-1 max-md:text-4xl">
        {number}
      </div>
      <div className="mt-2.5 text-xl font-medium" dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
}