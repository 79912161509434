// src/components/Header/Header.js
import React, { useState, useCallback } from "react";
import NavigationLink from "./NavigationLink";
import AuthButton from "./AuthButton";
import MobileMenu from "./MobileMenu";
import LoginModal from "../Login/LoginModal"; // Import the LoginModal component

const navigationLinks = [
  { label: "Home", href: "/" },
  { label: "About", href: "/about" },
  { label: "Services", href: "/services" },
  { label: "Contact", href: "/contact" },
];

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // State for LoginModal

  // Functions to open and close mobile menu
  const openMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(true);
  }, []);

  const closeMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(false);
  }, []);

  // Functions to open and close LoginModal
  const openLoginModal = useCallback(() => {
    setIsLoginModalOpen(true);
    closeMobileMenu(); // Optionally close mobile menu when modal is opened
  }, [closeMobileMenu]);

  const closeLoginModal = useCallback(() => {
    setIsLoginModalOpen(false);
  }, []);

  return (
    <>
      <nav
        className="relative flex flex-wrap gap-5 justify-between items-center px-4 py-4 w-full text-xl font-medium max-w-[1383px] mx-auto"
        role="navigation"
        aria-label="Main navigation"
      >
        <div className="text-3xl font-black text-blue-600">EARN</div>

        {/* Mobile Menu Toggle Button */}
        <button
          className="lg:hidden p-2 rounded-md hover:bg-gray-100"
          onClick={isMobileMenuOpen ? closeMobileMenu : openMobileMenu}
          aria-expanded={isMobileMenuOpen}
          aria-controls="mobile-menu"
          aria-label={
            isMobileMenuOpen
              ? "Close navigation menu"
              : "Open navigation menu"
          }
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={
                isMobileMenuOpen
                  ? "M6 18L18 6M6 6l12 12"
                  : "M4 6h16M4 12h16M4 18h16"
              }
            />
          </svg>
        </button>

        {/* Desktop Navigation Links */}
        <div className="hidden lg:flex gap-10 items-center text-center text-black whitespace-nowrap">
          {navigationLinks.map((link) => (
            <NavigationLink key={link.href} {...link} />
          ))}
        </div>

        {/* Desktop Auth Buttons */}
        <div className="hidden lg:flex gap-8 items-center text-center">
          <AuthButton
            variant="primary"
            label="Sign Up"
            onClick={openLoginModal} // Open LoginModal when clicked
          />
        </div>

        {/* Mobile Menu */}
        <MobileMenu
          isOpen={isMobileMenuOpen}
          links={navigationLinks}
          onClose={closeMobileMenu}
          onSignUp={openLoginModal} // Pass openLoginModal as onSignUp
        />
      </nav>

      {/* Login Modal */}
      <LoginModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
    </>
  );
}

export default Header;
