import React from 'react';

const suggestions = [
  "illustration",
  "logo design",
  "icon design",
  "business profile",
  "brochure design",
  "business card design"
];

export default function AISection() {
  return (
    <div className="flex flex-wrap gap-8 py-5 pr-7 pl-3.5 mt-6 bg-blue-600 rounded-xl max-md:pr-5">
      <div className="flex gap-2.5 text-2xl font-black text-white">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/f64613eab9213a05b7682f8bc6cd20e967065cc9ada02c219109db5d5412357e?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
          alt=""
          className="object-contain shrink-0 w-10 aspect-square"
        />
        <div className="self-start basis-auto">AI Suggestions</div>
      </div>
      <div className="flex flex-wrap flex-auto gap-6 self-start text-xl text-neutral-900">
        {suggestions.map((suggestion, index) => (
          <div key={index} className="px-5 py-1.5 bg-white rounded-xl border border-solid border-black border-opacity-20">
            {suggestion}
          </div>
        ))}
      </div>
    </div>
  );
}