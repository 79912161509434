import React from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MailBoxIcon from "../../asserts/mailboxIcon.png";

const ConfirmEmail = ({ userData, onNext, onBack }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        bgcolor: "#EAF4E9",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "70vw",
          height: "60vh",
          bgcolor: "white",
          borderRadius: "16px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            flex: 1,
            bgcolor: "#0D3D12",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            src={MailBoxIcon}
            alt="Mailbox Icon"
            sx={{
              width: "200px",
              height: "200px",
              objectFit: "contain",
            }}
          />
        </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            px: 6,
          }}
        >
          <IconButton
            onClick={onBack}
            sx={{
              alignSelf: "flex-start",
              mb: 2,
              color: "#4caf50",
            }}
          >
            <ArrowBackIcon />
          </IconButton>

          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              mb: 2,
              color: "#333",
            }}
          >
            Confirm your email
          </Typography>
          <Typography
            variant="body2"
            sx={{
              mb: 4,
              color: "#666",
              textAlign: "center",
            }}
          >
            We've sent a confirmation link to your email address. Please check
            your inbox to verify your account.
          </Typography>

          <Button
            variant="contained"
            sx={{
              bgcolor: "#4caf50",
              color: "white",
              textTransform: "none",
              fontWeight: "bold",
              borderRadius: "12px",
              px: 4,
              py: 1.5,
              width: "60%",
              "&:hover": { bgcolor: "#45a049" },
            }}
            onClick={onNext}
          >
            I’ve Confirmed
          </Button>

          <Typography
            variant="body2"
            sx={{
              mt: 2,
              color: "#666",
              textAlign: "center",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Resend email
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmEmail;
