import * as React from "react";

export function BusinessCTA() {
  const handleClick = () => {
    window.location.href = '/download';
  };

  return (
    <button
      onClick={handleClick}
      className="px-8 py-3 mt-10 text-lg font-semibold text-white bg-blue-600 rounded-full shadow-md hover:bg-blue-700 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 w-auto max-md:w-full max-md:py-4"
      tabIndex={0}
      aria-label="Download the app"
    >
      Get the App
    </button>
  );
}
