// src/components/Login/SellingOrBuying.js
import React, { useState } from "react";
import SellerIcon from "../../asserts/seller.png";
import BuyerIcon from "../../asserts/buyer.png";

const SellingOrBuying = ({ userData, onNext, onBack }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextClick = () => {
    if (!selectedOption) {
      alert("Please select an option to proceed.");
      return;
    }
    onNext({ role: selectedOption });
  };

  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 bg-white w-full max-w-md">
      <h2 className="text-2xl font-bold text-center mb-2">
        {`${userData.username}, your account has been created, let’s help you quickly get started`}
      </h2>
      <p className="text-gray-600 text-center mb-6">
        We want to tailor your experience so you’ll feel right at home
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 w-full">
        {/* Buyer Option */}
        <div
          onClick={() => handleOptionSelect("buyer")}
          className={`flex flex-col items-center justify-center p-4 border rounded-lg cursor-pointer transition-shadow ${
            selectedOption === "buyer"
              ? "border-green-500 shadow-lg"
              : "border-gray-300"
          } hover:shadow-lg`}
        >
          <img
            src={BuyerIcon}
            alt="Buyer Icon"
            className="w-12 h-12 mb-2"
          />
          <h3 className="text-lg font-semibold text-center text-gray-800">
            I want to buy Freelance Services
          </h3>
        </div>

        {/* Freelancer Option */}
        <div
          onClick={() => handleOptionSelect("freelancer")}
          className={`flex flex-col items-center justify-center p-4 border rounded-lg cursor-pointer transition-shadow ${
            selectedOption === "freelancer"
              ? "border-green-500 shadow-lg"
              : "border-gray-300"
          } hover:shadow-lg`}
        >
          <img
            src={SellerIcon}
            alt="Seller Icon"
            className="w-12 h-12 mb-2"
          />
          <h3 className="text-lg font-semibold text-center text-gray-800">
            I want to sell Freelance Services
          </h3>
        </div>
      </div>

      <div className="flex space-x-4 mt-6">
        {onBack && (
          <button
            onClick={onBack}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition"
          >
            Back
          </button>
        )}
        <button
          onClick={handleNextClick}
          className={`px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition ${
            !selectedOption ? "bg-gray-300 cursor-not-allowed" : ""
          }`}
          disabled={!selectedOption}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SellingOrBuying;
