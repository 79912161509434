import * as React from "react";

const ImageGridItem = ({ src, roundedClass }) => (
  <div className="flex flex-col w-full max-md:ml-0 max-md:w-full">
    <img
      loading="lazy"
      src={src}
      alt=""
      className={`object-contain w-full aspect-square ${roundedClass} max-md:mt-2`}
    />
  </div>
);

const ImageRow = ({ images }) => (
  <div className="flex gap-5 max-md:flex-col">
    {images.map((image, index) => (
      <ImageGridItem key={index} {...image} />
    ))}
  </div>
);

export default function FreelanceSolution() {
  const leftColumnImages = [
    [
      { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/087bde4ad23748823692ec2d0d06b95bfe1afdfe0d2f6c670c25b448102e38e6?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff", roundedClass: "rounded-[960px]" },
      { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/b2cadf0f6876a300cb04dd0a1bfdeae725d83e3999553da84629d8c8eff76c16?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff", roundedClass: "rounded-[50px]" }
    ],
    [
      { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/bc0213897a54768afc6a43a834e987f2933706fbc035522454c0f549be70221e?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff", roundedClass: "rounded-[300px]" },
      { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/0bfec24618db9c52aea6731c1c8eb62e457ce16bc334f6de7e757e459044d3ed?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff", roundedClass: "rounded-[133px]" }
    ]
  ];

  const rightColumnImages = [
    [
      { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/8dc854ac86a00480fb9dd395a6af8f0e681211aa5935dd431999967f7afb47f5?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff", roundedClass: "rounded-[128px]" },
      { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/bb0687070a244c7d145a1092e3b77268184aa2815b184576b1f867f1ed0a0c81?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff", roundedClass: "rounded-[50px]" },
      { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/d0a1706b1c0e992ada0ca61c9f53a06b091329f7ec94eff1214dd24d13bed2a8?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff", roundedClass: "rounded-[200px]" }
    ],
    [
      { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/2757ed1c23ffa494ac7d248dd53199d2e79803dbb950f374da495d3f54a386df?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff", roundedClass: "rounded-[50px]" },
      { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/9ea165d444cf2df5a27f8fc7cbe9953821b8ac54185f20a27d4911e9acaa63c5?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff", roundedClass: "rounded-[128px]" },
      { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/a9ca48e2c6d2fbf4382ca1b66e0d631ea73cb4877213b7c4dc6a34933c9f4e1b?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff", roundedClass: "rounded-[128px]" }
    ]
  ];

  return (
    <div className="p-8 max-md:p-4 bg-gray-100"> {/* Added padding around the blue box */}
      <div className="flex flex-col px-16 pt-24 pb-40 w-full bg-blue-950 rounded-[45px] max-md:px-5 max-md:pb-24 max-md:max-w-full">
        <h1 className="self-center ml-3.5 text-5xl font-bold text-center text-white max-md:max-w-full max-md:text-4xl">
          The <span className="italic text-indigo-400">Ultimate</span> Freelance{" "}
          <br />
          Solution for Businesses
        </h1>
        <div className="mt-24 -mb-8 max-md:mt-10 max-md:mb-2.5 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            <div className="flex flex-col w-[41%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col w-full max-md:mt-1 max-md:max-w-full">
                {leftColumnImages.map((row, index) => (
                  <div key={index} className="max-md:mr-0.5 max-md:max-w-full">
                    <ImageRow images={row} />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[59%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col w-full max-md:max-w-full">
                {rightColumnImages.map((row, index) => (
                  <div key={index} className="max-md:max-w-full">
                    <ImageRow images={row} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
