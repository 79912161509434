// src/components/Profile/ProfileCard.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAuth } from "../../context/AuthContext";

export function ProfileCard({ name, location, joinDate, avatarUrl }) {
  const { updateUserProfile } = useAuth();

  // Local state for editing
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(name);
  const [editedLocation, setEditedLocation] = useState(location);
  const [editedAvatarUrl, setEditedAvatarUrl] = useState(avatarUrl);
  const [avatarFile, setAvatarFile] = useState(null); // For file uploads
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Reset edited fields when props change (e.g., after saving)
  useEffect(() => {
    setEditedName(name);
    setEditedLocation(location);
    setEditedAvatarUrl(avatarUrl);
  }, [name, location, avatarUrl]);

  // Handle Save Action
  const handleSave = async () => {
    setError(null);
    setLoading(true);

    // Simple validation
    if (!editedName.trim() || !editedLocation.trim()) {
      setError("Name and location cannot be empty.");
      setLoading(false);
      return;
    }

    try {
      let newAvatarUrl = editedAvatarUrl;

      // If a new avatar file is selected, simulate uploading it
      if (avatarFile) {
        // In a real app, you'd upload the file to a server or cloud storage and get the URL
        // Here, we'll use a placeholder URL by creating a temporary URL
        newAvatarUrl = URL.createObjectURL(avatarFile);
      }

      // Update user profile via AuthContext
      const result = await updateUserProfile({
        name: editedName,
        location: editedLocation,
        avatarUrl: newAvatarUrl,
      });

      if (result.success) {
        setIsEditing(false);
        setAvatarFile(null);
      } else {
        setError(result.error);
      }
    } catch (err) {
      setError("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  // Handle Cancel Action
  const handleCancel = () => {
    setIsEditing(false);
    setEditedName(name);
    setEditedLocation(location);
    setEditedAvatarUrl(avatarUrl);
    setAvatarFile(null);
    setError(null);
  };

  // Handle Avatar File Change
  const handleAvatarChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setAvatarFile(e.target.files[0]);
      setEditedAvatarUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <div className="flex flex-col px-5 pt-12 pb-6 w-full text-sm font-medium text-black bg-white rounded-3xl border border-solid border-black border-opacity-20 shadow-lg">
      {/* Avatar */}
      <div className="flex justify-center">
        {isEditing ? (
          <div className="relative">
            <img
              src={editedAvatarUrl || "https://via.placeholder.com/150"}
              alt={`${editedName}'s profile`}
              className="w-24 h-24 rounded-full object-cover"
            />
            <label
              htmlFor="avatar-upload"
              className="absolute bottom-0 right-0 bg-blue-500 text-white rounded-full p-1 cursor-pointer"
              title="Change Avatar"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.172 7l-6.586 6.586a2 2 0 001.414 3.414L17 8.414M19 19l-7-7" />
              </svg>
            </label>
            <input
              type="file"
              id="avatar-upload"
              accept="image/*"
              className="hidden"
              onChange={handleAvatarChange}
            />
          </div>
        ) : (
          <div className="flex shrink-0 self-center bg-gray-200 rounded-full h-[83px] w-[83px] overflow-hidden">
            {avatarUrl ? (
              <img src={avatarUrl} alt={`${name}'s profile picture`} className="w-full h-full object-cover" />
            ) : (
              <span className="text-xl font-bold text-gray-700">{name.charAt(0).toUpperCase()}</span>
            )}
          </div>
        )}
      </div>

      {/* Name */}
      <div className="self-center mt-5 text-2xl font-bold">
        {isEditing ? (
          <input
            type="text"
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
            className="border-b border-gray-300 focus:outline-none focus:border-blue-500 text-center w-48"
            placeholder="Name"
          />
        ) : (
          name
        )}
      </div>

      {/* Location */}
      <div className="flex gap-5 justify-between px-4 py-3.5 mt-9 w-full rounded-xl bg-zinc-100 max-md:pr-5">
        <div className="flex gap-2">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/bfa41d8c76b59e1886cf0650375b8eda3d0dd414d78e346c65fee6d45dfcdcdd?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
            alt="Location Icon"
            className="object-contain shrink-0 aspect-square w-[19px]"
          />
          {isEditing ? (
            <input
              type="text"
              value={editedLocation}
              onChange={(e) => setEditedLocation(e.target.value)}
              className="border-b border-gray-300 focus:outline-none focus:border-blue-500 w-48"
              placeholder="Location"
            />
          ) : (
            <div className="basis-auto">{location}</div>
          )}
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/5e4fa6790f6a9c3ee2f45229dbb1ca0f5c869ef7e09c18334f8d53e18d8c5f93?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
          alt="Arrow Icon"
          className="object-contain shrink-0 my-auto w-3 aspect-square"
        />
      </div>

      {/* Join Date */}
      <div className="flex gap-5 justify-between px-5 py-3.5 mt-3 w-full rounded-xl bg-zinc-100 max-md:pr-5">
        <div className="flex gap-2.5">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/86248ff9bb1d773fa63977b7ba42164f3632b5d56e16f36464102eebb47b8344?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
            alt="Join Date Icon"
            className="object-contain shrink-0 self-start aspect-square w-[17px]"
          />
          <div className="basis-auto">
            {isEditing ? (
              <input
                type="text"
                value={joinDate}
                readOnly
                className="bg-transparent focus:outline-none w-32 text-gray-600"
                title="Join Date"
              />
            ) : (
              `Joined ${joinDate}`
            )}
          </div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/5e4fa6790f6a9c3ee2f45229dbb1ca0f5c869ef7e09c18334f8d53e18d8c5f93?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
          alt="Arrow Icon"
          className="object-contain shrink-0 my-auto w-3 aspect-square"
        />
      </div>

      {/* Edit/Save/Cancel Buttons */}
      <div className="flex justify-center mt-5">
        {isEditing ? (
          <div className="flex gap-4">
            <button
              onClick={handleCancel}
              className="flex items-center gap-2 px-5 py-2 bg-gray-300 text-gray-700 rounded-xl hover:bg-gray-400 transition"
              disabled={loading}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="flex items-center gap-2 px-5 py-2 bg-blue-500 text-white rounded-xl hover:bg-blue-600 transition"
              disabled={loading}
            >
              {loading ? (
                <svg
                  className="animate-spin h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              )}
              Save
            </button>
          </div>
        ) : (
          <button
            onClick={() => setIsEditing(true)}
            className="flex items-center gap-2 px-5 py-2 bg-green-500 text-white rounded-xl hover:bg-green-600 transition"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5M16.5 2.5a2.121 2.121 0 013 3L7 19l-4 1 1-4L16.5 2.5z" />
            </svg>
            Edit Profile
          </button>
        )}
      </div>

      {/* Error Message */}
      {error && (
        <p className="mt-2 text-center text-red-500">{error}</p>
      )}

      {/* Preview Public Profile Button */}
      <button
        onClick={() => window.open(`/public-profile/${name.replace(/\s+/g, "")}`, "_blank")}
        className="flex gap-5 px-12 py-3 mt-5 rounded-xl border-2 border-solid border-black border-opacity-20 max-md:px-5"
      >
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe5ab0c358ff1e39fb047ae9fc099c224b0b987be3b8a8b2865ab08271894a8c?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
          alt="Preview Icon"
          className="object-contain shrink-0 w-6 aspect-square"
        />
        <span className="grow shrink my-auto w-[149px]">Preview public profile</span>
      </button>
    </div>
  );
}

ProfileCard.propTypes = {
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  joinDate: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
};

ProfileCard.defaultProps = {
  avatarUrl: "https://via.placeholder.com/150",
};
