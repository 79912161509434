import React, { createContext, useContext, useState } from "react";

// Create the ContentContext
const ContentContext = createContext();

// Custom hook to use the ContentContext
export const useContent = () => {
  return useContext(ContentContext);
};

// ContentProvider component to wrap your app
export const ContentProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const updateSearchQuery = (query) => {
    setSearchQuery(query);
    // You can add additional logic here, such as fetching search results
  };

  const value = {
    searchQuery,
    updateSearchQuery,
  };

  return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
