import * as React from "react";
import { StatsCard } from "../Hero/StatsCard";
import { ActionButton } from "../Hero/ActionButton";

export function StatsSection() {
  return (
    <div className="mt-16 w-full max-w-[1362px] mx-auto px-4 max-md:mt-10">
      <div className="flex gap-5 max-md:flex-col">
        {/* Left Image */}
        <div className="flex flex-col w-1/5 max-md:w-full">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/afcd30f9926746f98d812418a7eefffc/b2c3837e508feed563617e9b6fdaad6c82ce631c66e9e472d6fe533abd7fd6b7?apiKey=afcd30f9926746f98d812418a7eefffc&"
            alt="Company showcase visual"
            className="object-contain mt-14 w-[253px] rounded-3xl aspect-[0.67] max-md:mt-10 max-md:w-full"
          />
        </div>

        {/* Center Section */}
        <div className="flex flex-col ml-5 w-[59%] max-md:ml-0 max-md:w-full">
          <div className="grow max-md:mt-10">
            <div className="flex gap-5 max-md:flex-col">
              {/* Stats Card */}
              <div className="flex flex-col w-[26%] max-md:w-full">
                <StatsCard
                  number="100+"
                  description="Our Esteemed Clients and <br /> partners"
                  className="mt-40 text-white border border-solid bg-blue-950 border-opacity-50 rounded-[30px] max-md:mt-10"
                />
              </div>

              {/* Buttons and Stats */}
              <div className="flex flex-col ml-5 w-[74%] max-md:ml-0 max-md:w-full">
                {/* Action Buttons */}
                <div className="flex gap-10 max-md:flex-col">
                  <ActionButton variant="primary">Get Started</ActionButton>
                  <ActionButton variant="secondary">Get the app</ActionButton>
                </div>

                {/* Cards and Image */}
                <div className="mt-28 max-md:mt-10">
                  <div className="flex gap-5 max-md:flex-col">
                    {/* Image Card */}
                    <div className="flex flex-col w-[59%] max-md:w-full">
                      <div className="flex flex-col items-start px-5 pt-3 pb-5 mt-16 w-full bg-white border border-solid shadow-2xl border-opacity-50 rounded-[30px] max-md:mt-10">
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/afcd30f9926746f98d812418a7eefffc/5cff3607ad4e27604eb1e7518973b6591195e37560e826c31a46df76ec44cb37?apiKey=afcd30f9926746f98d812418a7eefffc&"
                          alt="Service showcase"
                          className="object-contain aspect-[1.15] w-[195px]"
                        />
                      </div>
                    </div>

                    {/* Stats Card */}
                    <div className="flex flex-col w-[41%] max-md:w-full">
                      <StatsCard
                        number="6+"
                        description="Years of <br /> Dedicated <br /> Service"
                        className="px-10 py-14 bg-cyan-100 text-blue-950 border border-solid border-opacity-50 rounded-[30px] max-md:px-5 max-md:mt-10"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Image */}
        <div className="flex flex-col ml-5 w-[21%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col px-px pt-10 mt-14 w-full bg-amber-400 border border-solid border-opacity-50 rounded-[30px] max-md:mt-10">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/afcd30f9926746f98d812418a7eefffc/06a1e55dc725b001a8bdc8c1b89547fa0fb028b01ebb7649983bd6cfcc306e88?apiKey=afcd30f9926746f98d812418a7eefffc&"
              alt="Featured content"
              className="object-contain w-full aspect-[0.81]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
