// src/components/Header/MobileMenu.js
import React from "react";
import NavigationLink from "./NavigationLink";
import AuthButton from "./AuthButton";

function MobileMenu({ isOpen, links, onClose, onSignUp }) { // Accept onSignUp prop
  if (!isOpen) return null;

  return (
    <div
      id="mobile-menu"
      className="lg:hidden fixed top-0 left-0 right-0 bottom-0 bg-white shadow-lg p-6 flex flex-col gap-6 w-full z-50 overflow-auto"
      role="menu"
      aria-labelledby="mobile-menu-toggle"
    >
      {/* Navigation Links */}
      <nav className="flex flex-col gap-4">
        {links.map((link) => (
          <NavigationLink
            key={link.href}
            {...link}
            className="text-lg"
            onClick={onClose} // Close menu when a link is clicked
          />
        ))}
      </nav>

      {/* Sign Up Button */}
      <div className="mt-auto">
        <AuthButton
          variant="primary"
          label="Sign Up"
          className="w-full"
          onClick={onSignUp} // Trigger LoginModal when clicked
        />
      </div>
    </div>
  );
}

export default MobileMenu;
