import * as React from "react";
import NavigationColumn from "./LandingPage/NavigationColumn";
import { categories, clientLinks, freelancerLinks, companyLinks } from "../components/LandingPage/data";

export default function Footer() {
  return (
    <footer className="bg-gray-100">
      <div className="container mx-auto flex flex-wrap gap-10 items-start rounded-none p-6 md:p-12">
        <div className="text-6xl font-black text-blue-600 basis-auto max-md:text-4xl">
          EARN
        </div>
        <div className="flex-auto mt-3.5 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            <NavigationColumn title="Categories" links={categories} />
            <NavigationColumn title="For Clients" links={clientLinks} />
            <NavigationColumn title="For Freelancers" links={freelancerLinks} />
            <NavigationColumn title="EARN" links={companyLinks} />
          </div>
        </div>
      </div>
    </footer>
  );
}
