// src/context/AuthContext.js

import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

// Create the AuthContext
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// Mock Data
const mockUser = {
  username: "JohnDoe", // Added username
  name: "John Doe",
  email: "john.doe@example.com",
  title: "Software Engineer",
  bio: "Passionate developer with expertise in React and Node.js.",
  avatarUrl: "https://via.placeholder.com/150",
  location: "Gaborone, Botswana", // Added location
  joinDate: "December 2024", // Added joinDate
  services: {
    scope: "Full-stack web development services.",
    list: ["Frontend Development", "Backend Development", "API Integration"],
  },
  deliverables: ["Responsive Website", "API Documentation", "Source Code"],
  turnaround: ["2 weeks for small projects", "1 month for complex projects"],
  notes: ["Revisions included", "Maintenance available upon request"],
  pricing: {
    basic: "$500",
    standard: "$1000",
    premium: "$1500",
  },
  reviews: [
    {
      name: "Alice Smith",
      rating: 5,
      review: "Outstanding work! Highly recommended.",
    },
    {
      name: "Bob Johnson",
      rating: 4,
      review: "Great experience working with John.",
    },
  ],
  portfolio: [
    {
      title: "E-commerce Website",
      imageUrl: "https://via.placeholder.com/300",
      description: "A full-featured e-commerce platform.",
      link: "https://example.com/e-commerce",
    },
    {
      title: "Social Media App",
      imageUrl: "https://via.placeholder.com/300",
      description: "A responsive social media application.",
      link: "https://example.com/social-media",
    },
  ],
};

const mockRelatedProfiles = [
  {
    name: "Jane Smith",
    location: "New York, NY",
    rating: "4.9",
    reviews: "120",
    description:
      "Expert in UI/UX design with a keen eye for detail and user-centric design principles.",
    price: "200",
  },
  {
    name: "Mike Brown",
    location: "San Francisco, CA",
    rating: "4.7",
    reviews: "85",
    description:
      "Specializes in mobile app development with experience in both iOS and Android platforms.",
    price: "180",
  },
  // Add more mock profiles as needed
];

// AuthProvider component to wrap your app
export const AuthProvider = ({ children }) => {
  // Initialize auth state from localStorage if available
  const [authState, setAuthState] = useState(() => {
    const storedState = localStorage.getItem("authState");
    return storedState
      ? JSON.parse(storedState)
      : {
          isAuthenticated: false,
          user: null,
          token: null,
          status: "idle", // 'idle' | 'loading' | 'success' | 'error'
          error: null,
          relatedProfiles: [],
        };
  });

  // Effect to persist auth state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("authState", JSON.stringify(authState));
  }, [authState]);

  // -------------------- Authentication Functions --------------------

  /**
   * Simulate Sign In Function
   * @param {Object} credentials - { email, password }
   * @returns {Object} - { success, user, error }
   */
  const signIn = async (credentials) => {
    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    try {
      // Simulate network delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Check credentials (for dummy purposes, any credentials are accepted)
      if (credentials.email && credentials.password) {
        // Simulate token
        const token = "dummy-auth-token";

        setAuthState({
          isAuthenticated: true,
          user: { ...mockUser },
          token,
          status: "success",
          error: null,
          relatedProfiles: [...mockRelatedProfiles],
        });

        return { success: true, user: mockUser };
      } else {
        throw new Error("Invalid credentials");
      }
    } catch (error) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: error.message,
      }));
      return { success: false, error: error.message };
    }
  };

  /**
   * Simulate Sign Up Function
   * @param {Object} data - User registration data
   * @returns {Object} - { success, user, error }
   */
  const signUp = async (data) => {
    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    try {
      // Simulate network delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Validate registration data
      if (data.name && data.email && data.password) {
        // Simulate token
        const token = "dummy-auth-token";

        // Simulate new user with username derived from name
        const newUser = {
          ...mockUser,
          username: data.name.replace(/\s+/g, ""), // Simple username derivation
          name: data.name,
          email: data.email,
          joinDate: new Date().toLocaleString("default", { month: "long", year: "numeric" }), // Set joinDate to current month and year
        };

        setAuthState({
          isAuthenticated: true,
          user: { ...newUser },
          token,
          status: "success",
          error: null,
          relatedProfiles: [...mockRelatedProfiles],
        });

        return { success: true, user: newUser };
      } else {
        throw new Error("Missing registration fields");
      }
    } catch (error) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: error.message,
      }));
      return { success: false, error: error.message };
    }
  };

  /**
   * Simulate Sign Out Function
   */
  const signOut = () => {
    setAuthState({
      isAuthenticated: false,
      user: null,
      token: null,
      status: "idle",
      error: null,
      relatedProfiles: [],
    });
    localStorage.removeItem("authState");
  };

  // -------------------- Profile Management Functions --------------------

  /**
   * Simulate Fetch User Profile
   * @returns {Object} - { success, user, error }
   */
  const fetchUserProfile = async () => {
    if (!authState.token) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: "No authentication token found",
      }));
      return { success: false, error: "No authentication token found" };
    }

    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    try {
      // Simulate network delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Return mock user data
      setAuthState((prev) => ({
        ...prev,
        user: { ...mockUser },
        status: "success",
        error: null,
      }));

      return { success: true, user: mockUser };
    } catch (error) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: "Failed to fetch profile",
      }));
      return { success: false, error: "Failed to fetch profile" };
    }
  };

  /**
   * Simulate Update User Profile
   * @param {Object} updatedData - Fields to update
   * @returns {Object} - { success, user, error }
   */
  const updateUserProfile = async (updatedData) => {
    if (!authState.token) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: "No authentication token found",
      }));
      return { success: false, error: "No authentication token found" };
    }

    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    try {
      // Simulate network delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Update mock user data
      const updatedUser = { ...authState.user, ...updatedData };
      setAuthState((prev) => ({
        ...prev,
        user: { ...updatedUser },
        status: "success",
        error: null,
      }));

      return { success: true, user: updatedUser };
    } catch (error) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: "Failed to update profile",
      }));
      return { success: false, error: "Failed to update profile" };
    }
  };

  // -------------------- Service Management Functions --------------------

  /**
   * Simulate Fetch User Services
   * @returns {Object} - { success, services, error }
   */
  const fetchUserServices = async () => {
    if (!authState.token) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: "No authentication token found",
      }));
      return { success: false, error: "No authentication token found" };
    }

    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    try {
      // Simulate network delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Return mock services data
      setAuthState((prev) => ({
        ...prev,
        user: {
          ...prev.user,
          services: { ...mockUser.services },
          deliverables: [...mockUser.deliverables],
          turnaround: [...mockUser.turnaround],
          notes: [...mockUser.notes],
        },
        status: "success",
        error: null,
      }));

      return { success: true, services: mockUser.services };
    } catch (error) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: "Failed to fetch services",
      }));
      return { success: false, error: "Failed to fetch services" };
    }
  };

  /**
   * Simulate Update User Services
   * @param {Object} updatedData - { services, deliverables, turnaround, notes }
   * @returns {Object} - { success, services, error }
   */
  const updateUserServices = async (updatedData) => {
    if (!authState.token) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: "No authentication token found",
      }));
      return { success: false, error: "No authentication token found" };
    }

    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    try {
      // Simulate network delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Update mock services data
      const updatedServices = {
        ...authState.user.services,
        ...updatedData.services,
      };
      const updatedDeliverables = updatedData.deliverables || authState.user.deliverables;
      const updatedTurnaround = updatedData.turnaround || authState.user.turnaround;
      const updatedNotes = updatedData.notes || authState.user.notes;

      setAuthState((prev) => ({
        ...prev,
        user: {
          ...prev.user,
          services: { ...updatedServices },
          deliverables: [...updatedDeliverables],
          turnaround: [...updatedTurnaround],
          notes: [...updatedNotes],
        },
        status: "success",
        error: null,
      }));

      return { success: true, services: updatedServices };
    } catch (error) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: "Failed to update services",
      }));
      return { success: false, error: "Failed to update services" };
    }
  };

  // -------------------- Review Management Functions --------------------

  /**
   * Simulate Fetch User Reviews
   * @returns {Object} - { success, reviews, error }
   */
  const fetchUserReviews = async () => {
    if (!authState.token) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: "No authentication token found",
      }));
      return { success: false, error: "No authentication token found" };
    }

    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    try {
      // Simulate network delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Return mock reviews data
      setAuthState((prev) => ({
        ...prev,
        user: {
          ...prev.user,
          reviews: [...mockUser.reviews],
        },
        status: "success",
        error: null,
      }));

      return { success: true, reviews: mockUser.reviews };
    } catch (error) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: "Failed to fetch reviews",
      }));
      return { success: false, error: "Failed to fetch reviews" };
    }
  };

  /**
   * Simulate Add User Review
   * @param {Object} reviewData - { name, rating, review }
   * @returns {Object} - { success, review, error }
   */
  const addUserReview = async (reviewData) => {
    if (!authState.token) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: "No authentication token found",
      }));
      return { success: false, error: "No authentication token found" };
    }

    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    try {
      // Simulate network delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Validate review data
      if (!reviewData.name || !reviewData.rating || !reviewData.review) {
        throw new Error("Missing review fields");
      }

      // Create new review
      const newReview = {
        name: reviewData.name,
        rating: reviewData.rating,
        review: reviewData.review,
      };

      // Update mock reviews
      setAuthState((prev) => ({
        ...prev,
        user: {
          ...prev.user,
          reviews: [newReview, ...prev.user.reviews],
        },
        status: "success",
        error: null,
      }));

      return { success: true, review: newReview };
    } catch (error) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: error.message,
      }));
      return { success: false, error: error.message };
    }
  };

  // -------------------- Portfolio Management Functions --------------------

  /**
   * Simulate Fetch User Portfolio
   * @returns {Object} - { success, portfolio, error }
   */
  const fetchUserPortfolio = async () => {
    if (!authState.token) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: "No authentication token found",
      }));
      return { success: false, error: "No authentication token found" };
    }

    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    try {
      // Simulate network delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Return mock portfolio data
      setAuthState((prev) => ({
        ...prev,
        user: {
          ...prev.user,
          portfolio: [...mockUser.portfolio],
        },
        status: "success",
        error: null,
      }));

      return { success: true, portfolio: mockUser.portfolio };
    } catch (error) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: "Failed to fetch portfolio",
      }));
      return { success: false, error: "Failed to fetch portfolio" };
    }
  };

  /**
   * Simulate Add Portfolio Item
   * @param {Object} portfolioData - { title, imageUrl, description, link }
   * @returns {Object} - { success, portfolioItem, error }
   */
  const addPortfolioItem = async (portfolioData) => {
    if (!authState.token) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: "No authentication token found",
      }));
      return { success: false, error: "No authentication token found" };
    }

    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    try {
      // Simulate network delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Validate portfolio data
      if (!portfolioData.title || !portfolioData.imageUrl || !portfolioData.description || !portfolioData.link) {
        throw new Error("Missing portfolio fields");
      }

      // Create new portfolio item
      const newPortfolioItem = {
        title: portfolioData.title,
        imageUrl: portfolioData.imageUrl,
        description: portfolioData.description,
        link: portfolioData.link,
      };

      // Update mock portfolio
      setAuthState((prev) => ({
        ...prev,
        user: {
          ...prev.user,
          portfolio: [newPortfolioItem, ...prev.user.portfolio],
        },
        status: "success",
        error: null,
      }));

      return { success: true, portfolioItem: newPortfolioItem };
    } catch (error) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: error.message,
      }));
      return { success: false, error: error.message };
    }
  };

  // -------------------- Related Profiles Functions --------------------

  /**
   * Simulate Fetch Related Profiles
   * @returns {Object} - { success, relatedProfiles, error }
   */
  const fetchRelatedProfiles = async () => {
    if (!authState.token) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: "No authentication token found",
      }));
      return { success: false, error: "No authentication token found" };
    }

    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    try {
      // Simulate network delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Return mock related profiles
      setAuthState((prev) => ({
        ...prev,
        relatedProfiles: [...mockRelatedProfiles],
        status: "success",
        error: null,
      }));

      return { success: true, relatedProfiles: mockRelatedProfiles };
    } catch (error) {
      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: "Failed to fetch related profiles",
      }));
      return { success: false, error: "Failed to fetch related profiles" };
    }
  };

  // -------------------- Context Value --------------------

  const value = {
    // Authentication
    signIn,
    signUp,
    signOut,

    // Profile Management
    fetchUserProfile,
    updateUserProfile,

    // Service Management
    fetchUserServices,
    updateUserServices,

    // Review Management
    fetchUserReviews,
    addUserReview,

    // Portfolio Management
    fetchUserPortfolio,
    addPortfolioItem,

    // Related Profiles
    fetchRelatedProfiles,

    // Auth State
    authState,
    setAuthState,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// PropTypes for AuthProvider
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
