import React from 'react';
import MarketplaceHeader from '../components/Dashboard/MarketplaceHeader';
import CategoryNav from '../components/Dashboard/CategoryNav';
import AISection from '../components/Dashboard/AISection';
import FilterSection from '../components/Dashboard/FilterSection';
import ServiceCard from '../components/Dashboard/ServiceCard';
import Pagination from '../components/Dashboard/Pagination';
import Footer from '../components/Footer';

export default function Dashboard() {
  return (
    <div className="flex flex-col p-4 rounded-none">
      <MarketplaceHeader />
      <div className="flex mt-5 w-full bg-black bg-opacity-10 min-h-[2px] max-md:max-w-full" />
      <div className="flex flex-col px-16 mt-5 w-full max-md:px-5 max-md:max-w-full">
        <AISection />
        <div className="self-start mt-7 text-3xl font-bold text-black max-md:ml-0.5">
          <span>Results for</span>{" "}
          <span className="italic font-black">graphic design </span>
        </div>
        <FilterSection />
        <div className="flex flex-wrap gap-5 justify-between mt-4 w-full text-xl font-bold text-neutral-400 max-md:mr-1.5 max-md:max-w-full">
          <div>2000+ results</div>
          <div className="flex gap-5 self-start">
            <div className="grow">
              sort by: <span className="text-black">Relevance</span>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f7b48b1148dbe43b7f724bb4c8f311c7a47baa45d45c9b9199f7206592968e89?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
              alt=""
              className="object-contain shrink-0 self-start mt-1 w-5 aspect-square"
            />
          </div>
        </div>
        <div className="flex flex-wrap gap-9 mt-8">
          {Array.from({ length: 20 }, (_, i) => (
            <ServiceCard key={i} />
          ))}
        </div>
        <Pagination />
        <Footer/>
      </div>
    </div>
  );
}
