import * as React from "react";

export default function NavigationColumn({ title, links }) {
  return (
    <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
      <div className="flex flex-col items-start text-xl text-zinc-500 max-md:mt-10">
        <div className="text-2xl font-bold text-neutral-600">
          {title}
        </div>
        {links.map((link, index) => (
          <div 
            key={index}
            className={`${index === 0 ? "mt-9" : "mt-3"} ${title === "For Freelancers" ? "pl-1" : ""}`}
            tabIndex="0"
            role="link"
          >
            {link}
          </div>
        ))}
      </div>
    </div>
  );
}