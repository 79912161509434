import * as React from "react";
import { ActionButton } from "./ActionButton";

export function HeroContent() {
  return (
    <>
      <h1 className="text-5xl font-bold text-white leading-[72px] max-md:max-w-full max-md:text-4xl max-md:leading-[67px]">
        At Earn, we're more than a platform—we're a partner in your success.
        Whether you're building your career or growing your business, choose
        Earn and experience freelancing the way it's meant to be.
      </h1>
      <ActionButton />
    </>
  );
}