import * as React from "react";

export function ServiceCard({ icon, title, description }) {
  return (
    <div className="flex flex-col w-full max-lg:w-[48%] max-md:w-full">
      <div className="flex flex-col p-6 w-full text-white bg-blue-600 rounded-2xl shadow-md transition-transform hover:scale-105 max-md:p-4">
        {/* Icon */}
        <img
          loading="lazy"
          src={icon}
          alt={`${title} icon`}
          className="self-end w-12 h-12 mb-4 max-md:w-10 max-md:h-10"
        />
        
        {/* Title */}
        <div className="text-2xl font-bold leading-snug max-md:text-xl">
          {title}
        </div>
        
        {/* Description */}
        <div className="mt-4 text-base font-medium leading-relaxed text-gray-200 max-md:text-sm">
          {description}
        </div>
      </div>
    </div>
  );
}
