import React from 'react';

function FeatureCard({ title, description, highlighted }) {
  const containerClasses = highlighted
    ? "flex flex-wrap gap-6 px-11 py-2.5 mt-5 w-full bg-indigo-100 rounded-[40px] max-md:px-5 max-md:max-w-full"
    : "flex flex-wrap gap-6 mt-5 max-w-full w-[451px]";

  return (
    <div className={containerClasses}>
      <div className="flex items-center justify-center shrink-0 my-auto bg-blue-600 rounded-full h-[34px] w-[34px] text-white font-bold">
        ✓
      </div>
      <div className="flex flex-col grow shrink-0 basis-0 w-fit max-md:max-w-full">
        <div className="self-start text-2xl font-bold text-black">
          {title}
        </div>
        <div className="mt-2.5 text-2xl font-medium text-stone-500 max-md:max-w-full">
          {description}
        </div>
      </div>
    </div>
  );
}

export default FeatureCard;
