// src/pages/Profile.js

import React, { useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import MarketplaceHeader from "../components/Dashboard/MarketplaceHeader";
import {ProfileCard} from "../components/Profile/ProfileCard";
import {FreelancerSwitch} from "../components/Profile/FreelancerSwitch";
import {SetupCard} from "../components/Profile/SetupCard";

const setupSteps = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0ae53b415d1caa081a9aa4c1f89036e730c6f2b70deb644a24169d7f6174e66d?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff",
    title: "Share how you plan to use Earn",
    description: "Let us know if you want to offer your services or buy services from freelancers",
    action: "",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/75ede04cc5b5db6f189755bb4669203be1da8efda965cc8dd639c044c47cf746",
    title: "Add details to your profile",
    description: "Upload a photo and add more details to enhance your profile",
    action: "ADD",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/12cc8a07920d5e0d00b1faeff34d2168f22518606f9d2f908e19b60ca5d99a93?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff",
    title: "Set your communication preferences",
    description: "Let us know your preferred methods of communication",
    action: "ADD",
  },
];

export default function Profile() {
  const { authState, fetchUserProfile } = useAuth();
  const { user, status, error } = authState;

  useEffect(() => {
    if (authState.isAuthenticated && !user) {
      fetchUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.isAuthenticated]);

  if (!authState.isAuthenticated) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <p className="text-xl text-gray-700">Please sign in to view your profile.</p>
      </div>
    );
  }

  if (status === "loading" && !user) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <p className="text-xl text-gray-700">Loading your profile...</p>
      </div>
    );
  }

  if (status === "error") {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <p className="text-xl text-red-600">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="p-4 bg-gray-50 min-h-screen">
      <MarketplaceHeader />

      <div className="flex flex-col md:flex-row gap-5 mt-6">
        {/* Left Sidebar */}
        <div className="flex flex-col md:w-1/4 w-full">
          <ProfileCard
            name={user.name}
            location={user.location}
            joinDate={user.joinDate}
            avatarUrl={user.avatarUrl}
            bio={user.bio}
            title={user.title}
          />
          <FreelancerSwitch />
        </div>

        {/* Main Content */}
        <div className="flex flex-col md:w-3/4 w-full">
          <div className="bg-white p-6 rounded-3xl border border-gray-200">
            <h2 className="text-3xl font-bold text-center text-black">Setting up your profile</h2>
            <p className="mt-3.5 text-base font-medium text-gray-700 text-center">
              Get the most out of Earn by sharing info about you
            </p>

            <h3 className="mt-7 text-3xl font-bold text-center text-black">Profile Setup</h3>

            {/* Progress Bar */}
            <div className="flex flex-col items-center mt-7">
              <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                <div
                  className="bg-green-500 h-2.5 rounded-full"
                  style={{ width: "50%" }} // Adjust the width based on completion
                ></div>
              </div>
            </div>

            {/* Setup Steps */}
            <div className="mt-4">
              {setupSteps.map((step, index) => (
                <SetupCard key={index} {...step} />
              ))}
            </div>
          </div>

          {/* Additional Sections */}
          <div className="mt-7 bg-white p-6 rounded-3xl border border-gray-200 flex flex-wrap gap-9">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/5bb95e5fc48defbd70cb5e5e28ebb73d4791a78d9280efc0c5f0aae274036fae?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
              alt="Order Icon"
              className="object-contain w-10 h-10"
            />
            <div className="flex flex-col flex-grow">
              <div className="flex justify-between items-center text-3xl font-bold text-black">
                <span>Order History</span>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/801d650bf8c7fe114248a9ea27f9dba26e809606991ea9d86a56261b5655121a?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
                  alt="Arrow Icon"
                  className="object-contain w-6 h-6"
                />
              </div>
              <p className="mt-2 text-base text-gray-700">A record of all the projects you have commissioned</p>
            </div>
          </div>

          {/* Reviews Section */}
          <div className="mt-7 bg-white p-6 rounded-3xl border border-gray-200 flex flex-col items-center">
            <h2 className="self-start text-2xl font-bold text-black">Reviews from Collaborators</h2>
            {user.reviews.length > 0 ? (
              <div className="w-full mt-4">
                {user.reviews.map((review, index) => (
                  <div key={index} className="flex items-start gap-4 mb-4">
                    <div className="w-12 h-12 rounded-full bg-gray-300 flex items-center justify-center text-white font-bold">
                      {review.name.charAt(0).toUpperCase()}
                    </div>
                    <div>
                      <div className="flex items-center gap-2">
                        <span className="text-lg font-semibold">{review.name}</span>
                        <span className="text-yellow-500">{'★'.repeat(review.rating)}</span>
                      </div>
                      <p className="mt-1 text-gray-700">{review.review}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/b78e45e7c4f668c15d728b8cf1411dc04b5d433449b4c1478a56261b5655121a?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
                  alt="No Reviews"
                  className="object-contain mt-10 w-24 h-24 animate-bounceConfirmation"
                />
                <p className="mt-3 text-xl text-gray-700 text-opacity-40">No reviews yet</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
