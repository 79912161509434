import React from 'react';

export default function ServiceCard() {
  return (
    <div className="flex flex-col flex-1 items-center py-5 bg-white rounded-2xl">
      <div className="flex shrink-0 bg-gray-200 rounded-2xl h-[134px] w-[215px]" />
      <div className="flex gap-5 justify-between mt-3.5 max-w-full font-bold w-[215px]">
        <div className="flex gap-1.5">
          <div className="flex flex-col">
            <div className="text-sm text-black">Katlego Lejowe</div>
            <div className="self-start text-xs text-zinc-500">
              Gaborone, block 8
            </div>
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/14574100c073f5ee3861b5a260c76230bc95f42fcf1a18f0458821ce046b0fbc?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
            alt=""
            className="object-contain shrink-0 self-start aspect-square w-[15px]"
          />
        </div>
        <div className="flex gap-1 self-start px-2 py-1.5 text-xs text-black rounded-lg bg-zinc-100">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7374d3dca00074a9d877c257d70f7503c6f2f6e8990fe0f96a82ff4eca2e3239?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
            alt=""
            className="object-contain shrink-0 self-start aspect-square w-[13px]"
          />
          <div>4.8 (70)</div>
        </div>
      </div>
      <div className="flex shrink-0 self-stretch mt-3 h-px bg-zinc-300" />
      <div className="mt-2.5 mr-4 ml-4 text-sm font-semibold text-black max-md:mx-2.5">
        I specialize in Resume and cover letter creation and have 2 years.......
      </div>
      <div className="flex gap-5 mt-2.5 max-w-full whitespace-nowrap w-[212px]">
        <button className="px-5 py-2.5 text-sm font-bold text-white bg-blue-600 rounded-xl max-md:px-5">
          Message
        </button>
        <div className="flex gap-1 my-auto">
          <div className="grow text-sm font-bold text-neutral-400">from</div>
          <div className="text-xl font-extrabold text-black">P150</div>
        </div>
      </div>
    </div>
  );
}