import * as React from "react";
import { StartEarningText } from "./StartEarningText";
import { StartEarningButton } from "./StartEarningButton";
import { StartEarningImage } from "./StartEarningImage";

export function StartEarningSection() {
  return (
    <section className="rounded-none p-6 md:p-10" aria-label="Start Earning Section">
      <div className="flex gap-5 max-md:flex-col">
        <div className="flex flex-col w-[58%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col self-stretch my-auto font-medium max-md:mt-10 max-md:max-w-full">
            <StartEarningText />
            <StartEarningButton />
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[42%] max-md:ml-0 max-md:w-full">
          <StartEarningImage />
        </div>
      </div>
    </section>
  );
}