import React from 'react';

export default function Pagination() {
  const pages = Array.from({ length: 8 }, (_, i) => i + 1);
  
  return (
    <div className="flex flex-wrap gap-5 justify-between items-center self-center mt-14 max-w-full text-lg font-bold text-black whitespace-nowrap w-[482px] max-md:mt-10">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/6b9f76cd410f47ff441886781004195ae9519f21e90af9befc70214375c11543?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
        alt="Previous page"
        className="object-contain shrink-0 self-stretch rounded-none aspect-square w-[52px]"
      />
      {pages.map((page) => (
        <div key={page} className="self-stretch my-auto">{page}</div>
      ))}
      <div className="self-stretch my-auto">...</div>
      <div className="self-stretch my-auto">18</div>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/1b7a4f0c2804399e8bb4afd3c3f58f4f6a505801cbf222a11891c52eb3edf68e?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
        alt="Next page"
        className="object-contain shrink-0 self-stretch rounded-none aspect-square w-[52px]"
      />
    </div>
  );
}