import * as React from "react";

export const SocialIcons = ({ icons }) => {
  return (
    <div className="flex gap-4 self-center mt-4 max-w-full w-[122px]">
      {icons.map((icon, index) => (
        <img
          key={index}
          loading="lazy"
          src={icon}
          alt=""
          className="object-contain shrink-0 aspect-[0.98] w-[53px]"
        />
      ))}
    </div>
  );
};