import * as React from "react";

export function StartEarningImage() {
  return (
    <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/ada5693f7b1a42db14a875b3c9aa2ddcede0dad882e7e5db83dc759efd8312fd?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
      alt="Freelancer working on Earn platform"
      className="object-contain grow w-full aspect-[0.77] rounded-[61px] max-md:mt-10 max-md:max-w-full"
      aria-hidden="false"
    />
  );
}