import * as React from "react";

export const ActionButton = ({ children, variant = "primary" }) => {
  const baseStyles = "px-7 py-4 text-xl font-medium text-center rounded-[50px] max-md:px-5";
  const variants = {
    primary: "text-white bg-blue-600",
    secondary: "text-black bg-white border-2 border-solid border-black border-opacity-20"
  };

  return (
    <button className={`${baseStyles} ${variants[variant]}`}>
      {children}
    </button>
  );
}