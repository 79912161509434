import * as React from "react";

export function FreelancerSwitch() {
  return (
    <div className="flex flex-col py-5 mt-5 text-white rounded-3xl border border-solid bg-blue-950 border-black border-opacity-20">
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/ef8cc35b7d1d85d671dc99db31ea2dadb2048bd21dd7d6076b08f858e586a328?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff" alt="" className="object-contain self-end mr-5 aspect-square w-[47px] max-md:mr-2.5" />
      <div className="flex flex-col px-8 max-md:px-5">
        <div className="self-start text-2xl font-bold">Switch to Freelancer</div>
        <div className="mt-1.5 text-base font-medium">
          This is your profile for ordering services. To manage your freelancer profile, switch to Freelancer
        </div>
      </div>
    </div>
  );
}