import * as React from "react";

export function BusinessImage() {
  return (
    <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/fd42a230b4ae52ad47d73aad479de2c66cffe8fc9acaee4dc05eefc781f9dabe?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
      alt="Business app interface showcase"
      className="object-contain self-stretch mt-2.5 w-full aspect-[0.93] max-md:max-w-full"
      onError={(e) => {
        e.currentTarget.src = '/fallback-image.png';
      }}
    />
  );
}