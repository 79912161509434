import * as React from "react";

export function StartEarningButton() {
  const handleClick = () => {
    // Handle click event
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  };

  return (
    <button 
      className="self-start px-7 py-4 mt-12 text-xl text-center text-white bg-blue-600 rounded-[50px] max-md:px-5 max-md:mt-10 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      tabIndex={0}
      aria-label="Get Started with Earn"
      role="button"
    >
      Get Started
    </button>
  );
}