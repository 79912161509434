import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { AuthProvider } from "./context/AuthContext"; // Import AuthProvider
import { ContentProvider } from "./context/ContentContext"; // Import ContentProvider

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <ContentProvider>
        <App />
      </ContentProvider>
    </AuthProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
