// src/components/Login/LoginModal.js

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import { SocialButton } from "./SocialButton";
import { SocialIcons } from "./SocialIcons";
import groupImage from "../../asserts/group.svg";
import confirmationImage from "../../asserts/confirmation.svg";
import { useAuth } from "../../context/AuthContext";
import SellingOrBuying from "./SellingorBuying";
import StartExploring from "./StartExploring"; // Import the StartExploring component

const socialButtons = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8144590fac1be2f9096ec044222c4706efb6fe3c6aa1fe4c425b285c94275cb1",
    text: "Continue with Google",
  },
];

const socialIcons = [
  "https://cdn.builder.io/api/v1/image/assets/TEMP/dcb4df6f0a2a2c3822963b71ff4c6bdbc8275ebda965cc8dd639c044c47cf746",
  "https://cdn.builder.io/api/v1/image/assets/TEMP/bc84a9e5bea8db9347a0910023be0564abfeb498625d4d0fab4a6782527dd0eb",
];

export default function LoginModal({ isOpen, onClose }) {
  const navigate = useNavigate(); // Initialize useNavigate
  const { signIn, signUp, register, authState, setAuthState } = useAuth();
  const { status, error } = authState;

  const [currentView, setCurrentView] = useState("signIn");
  const [direction, setDirection] = useState("down");

  // Changed 'fullName' to 'name' to align with AuthContext
  const [signUpData, setSignUpData] = useState({ name: "", email: "", password: "" });
  const [signInData, setSignInData] = useState({ email: "", password: "" });
  const [registerData, setRegisterData] = useState({ phone: "", address: "" });
  const [userData, setUserData] = useState({ username: "" }); // Assuming username is available after sign up

  useEffect(() => {
    if (currentView === "confirmation") {
      const heading = document.getElementById("confirmation-heading");
      if (heading) {
        heading.focus();
      }
    }
  }, [currentView]);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [onClose]);

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const goToSignIn = () => {
    setDirection("down");
    setCurrentView("signIn");
  };

  const goToSignUp = () => {
    setDirection("down");
    setCurrentView("signUp");
  };

  const goToRegister = () => {
    setDirection("up");
    setCurrentView("register");
  };

  const goToConfirmation = () => {
    setDirection("down");
    setCurrentView("confirmation");
  };

  const goToSellingOrBuying = () => {
    setDirection("down");
    setCurrentView("sellingBuying");
  };

  const goToStartExploring = () => {
    setDirection("down");
    setCurrentView("startExploring");
  };

  const handleNextFromSellingOrBuying = async (data) => {
    const { role } = data;

    // Update the auth context with the selected role
    setAuthState((prevState) => ({
      ...prevState,
      role: role,
    }));

    // Optionally, save the role to the backend or perform additional actions here

    // Transition to StartExploring view
    goToStartExploring();
  };

  // Transform classes logic
  const getTransformClasses = (viewName) => {
    const isActive = currentView === viewName;
    const inactiveClass = direction === "down" ? "-translate-y-full" : "translate-y-full";
    return isActive ? "opacity-100 translate-y-0" : `opacity-0 ${inactiveClass}`;
  };

  // Handlers for form submissions
  const handleSignUp = async (e) => {
    e.preventDefault();
    const result = await signUp(signUpData);
    if (result.success) {
      setUserData({ username: signUpData.name.replace(/\s+/g, ""), email: signUpData.email, password: signUpData.password });
      // Transition to confirmation view
      goToConfirmation();
    } else {
      console.error("Sign up failed", result.error);
      // Optionally, set error in authState if not already handled
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    const result = await signIn(signInData);
    if (result.success) {
      // Signed in successfully
      // Optionally, navigate to dashboard if not handled elsewhere
      navigate("/dashboard");
      onClose();
    } else {
      console.error("Sign in failed", result.error);
      // Optionally, set error in authState if not already handled
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const result = await register(registerData);
    if (result.success) {
      // Registration complete
      // Go back to signUp or close the modal
      goToSignUp();
    } else {
      console.error("Registration failed", result.error);
      // Optionally, set error in authState if not already handled
    }
  };

  return (
    <FocusTrap>
      <div
        className="fixed   inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-0 md:p-4 transition-opacity duration-300"
        role="dialog"
        aria-modal="true"
        aria-labelledby="login-modal-title"
        onClick={handleOverlayClick} // Handle click on overlay
      >
        <div
          className="relative bg-white rounded-none md:rounded-2xl w-full h-full md:w-full md:h-[90vh] max-w-4xl overflow-y-auto transition-transform duration-300"
          onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
        >
          {/* Close Button */}
          <button
            className="absolute top-4 right-4 text-gray-500 hover:text-black focus:outline-none z-10"
            aria-label="Close modal"
            onClick={onClose}
          >
            ✕
          </button>

          <div className="flex flex-col md:flex-row gap-6 h-full">
            {/* Left Section hidden on mobile */}
            <div className="hidden md:flex relative flex-1 items-center justify-center bg-gradient-to-br from-blue-900 to-blue-600 p-4 sm:p-8 h-full overflow-hidden">
              {/* Group Image */}
              <img
                src={groupImage}
                alt="Overlapping portraits"
                className={`
                  h-48 sm:h-60 md:h-72
                  w-auto
                  rounded-lg animate-float
                  transition-all duration-500
                  ${currentView === "confirmation" ? "opacity-0 translate-x-full" : "opacity-100 translate-x-0"}
                  absolute inset-0 m-auto
                `}
              />
              {/* Confirmation Image */}
              <img
                src={confirmationImage}
                alt="Confirmation"
                className={`
                  h-48 sm:h-60 md:h-72
                  w-auto
                  rounded-lg animate-bounceConfirmation
                  transition-all duration-500
                  ${currentView === "confirmation" ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-full"}
                  absolute inset-0 m-auto
                `}
              />
            </div>

            {/* Right Section with Animated Panels */}
            <div className="relative flex-1 p-6 m-2 md:p-14 flex flex-col justify-center h-full">
              {/* Sign Up Panel */}
              <div
                className={`
                  absolute inset-0 transition-all duration-500 ease-in-out
                  flex flex-col items-center justify-center h-full
                  ${getTransformClasses("signUp")}
                `}
              >
                <h1 className="text-xl md:text-3xl font-bold text-black">Create a New Account</h1>
                <p className="mt-2 text-sm md:text-base text-gray-700">
                  Already have an account?{" "}
                  <button onClick={goToSignIn} className="text-blue-600 underline">
                    Sign in
                  </button>
                </p>

                {error && <div className="text-red-600 mt-2">{error}</div>}
                {status === "loading" && <div className="text-gray-600 mt-2">Loading...</div>}

                <div className="mt-4 max-w-sm w-full space-y-4">
                  {socialButtons.map((button, index) => (
                    <SocialButton
                      key={index}
                      icon={button.icon}
                      text={button.text}
                      onClick={() => console.log(`${button.text} clicked`)}
                      className="w-full border border-gray-300 rounded-lg px-4 py-3 hover:bg-gray-100 transition flex items-center justify-center gap-2"
                    />
                  ))}
                </div>

                <div className="mt-4 text-center text-gray-700 font-bold">OR</div>

                <form onSubmit={handleSignUp} className="mt-4 max-w-sm w-full space-y-4">
                  <input
                    type="text"
                    placeholder="Full Name"
                    className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-600"
                    value={signUpData.name} // Changed from fullName to name
                    onChange={(e) => setSignUpData({ ...signUpData, name: e.target.value })}
                    required
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-600"
                    value={signUpData.email}
                    onChange={(e) => setSignUpData({ ...signUpData, email: e.target.value })}
                    required
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-600"
                    value={signUpData.password}
                    onChange={(e) => setSignUpData({ ...signUpData, password: e.target.value })}
                    required
                  />
                  <button
                    type="submit"
                    className="w-full bg-blue-600 text-white font-medium rounded-lg px-4 py-2 hover:bg-blue-700 transition focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
                  >
                    Sign Up
                  </button>
                </form>

                <div className="mt-4 text-center">
                  <button onClick={goToRegister} className="text-blue-600 underline text-sm">
                    More options
                  </button>
                </div>

                <div className="flex justify-center items-center mt-4 space-x-4">
                  <SocialIcons icons={socialIcons} />
                </div>

                <div className="mt-4 text-xs text-gray-500 text-center px-4">
                  By signing up, you agree to the Earn{" "}
                  <a href="/terms" className="text-blue-600 underline">Terms of Service</a>{" "}
                  and to receive occasional emails from us. Please read our{" "}
                  <a href="/privacy" className="text-blue-600 underline">Privacy Policy</a>{" "}
                  to learn how we use your data.
                </div>
              </div>

              {/* Sign In Panel */}
              <div
                className={`
                  absolute inset-0 transition-all duration-500 ease-in-out
                  flex flex-col items-center justify-center h-full
                  ${getTransformClasses("signIn")}
                `}
              >
                <h1 className="text-xl md:text-3xl font-bold text-black">Sign In to Your Account</h1>
                <p className="mt-2 text-sm md:text-base text-gray-700">
                  Don&apos;t have an account?{" "}
                  <button onClick={goToSignUp} className="text-blue-600 underline">
                    Sign up
                  </button>
                </p>

                {error && <div className="text-red-600 mt-2">{error}</div>}
                {status === "loading" && <div className="text-gray-600 mt-2">Loading...</div>}

                <div className="mt-4 max-w-sm w-full space-y-4">
                  {socialButtons.map((button, index) => (
                    <SocialButton
                      key={index}
                      icon={button.icon}
                      text={button.text.replace("Continue with", "Sign in with")}
                      onClick={() => console.log(`${button.text} clicked`)}
                      className="w-full border border-gray-300 rounded-lg px-4 py-3 hover:bg-gray-100 transition flex items-center justify-center gap-2"
                    />
                  ))}
                </div>

                <div className="mt-4 text-center text-gray-700 font-bold">OR</div>

                <form onSubmit={handleSignIn} className="mt-4 max-w-sm w-full space-y-4">
                  <input
                    type="email"
                    placeholder="Email"
                    className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-600"
                    value={signInData.email}
                    onChange={(e) => setSignInData({ ...signInData, email: e.target.value })}
                    required
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-600"
                    value={signInData.password}
                    onChange={(e) => setSignInData({ ...signInData, password: e.target.value })}
                    required
                  />
                  <button
                    type="submit"
                    className="w-full bg-blue-600 text-white font-medium rounded-lg px-4 py-2 hover:bg-blue-700 transition focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
                  >
                    Sign In
                  </button>
                </form>

                <div className="flex justify-center items-center mt-4 space-x-4">
                  <SocialIcons icons={socialIcons} />
                </div>

                <div className="mt-4 text-xs text-gray-500 text-center px-4">
                  By signing in, you agree to the Earn{" "}
                  <a href="/terms" className="text-blue-600 underline">Terms of Service</a>{" "}
                  and to receive occasional emails from us. Please read our{" "}
                  <a href="/privacy" className="text-blue-600 underline">Privacy Policy</a>{" "}
                  to learn how we use your data.
                </div>
              </div>

              {/* Register Panel */}
              <div
                className={`
                  absolute inset-0 transition-all duration-500 ease-in-out
                  flex flex-col items-center justify-center h-full
                  ${getTransformClasses("register")}
                `}
              >
                <h1 className="text-xl md:text-3xl font-bold text-black">Complete Your Registration</h1>
                <p className="mt-2 text-sm md:text-base text-gray-700">Add additional details for your account</p>

                {error && <div className="text-red-600 mt-2">{error}</div>}
                {status === "loading" && <div className="text-gray-600 mt-2">Loading...</div>}

                <form onSubmit={handleRegister} className="mt-4 max-w-sm w-full space-y-4">
                  <input
                    type="text"
                    placeholder="Phone Number"
                    className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-600"
                    value={registerData.phone}
                    onChange={(e) => setRegisterData({ ...registerData, phone: e.target.value })}
                    required
                  />
                  <input
                    type="text"
                    placeholder="Address"
                    className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-600"
                    value={registerData.address}
                    onChange={(e) => setRegisterData({ ...registerData, address: e.target.value })}
                    required
                  />
                  <button
                    type="submit"
                    className="w-full bg-green-600 text-white font-medium rounded-lg px-4 py-2 hover:bg-green-700 transition focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
                  >
                    Complete Registration
                  </button>
                </form>

                <div className="mt-4 text-center">
                  <button onClick={goToSignUp} className="text-blue-600 underline text-sm">
                    Back to Sign Up
                  </button>
                </div>

                <div className="flex justify-center items-center mt-4 space-x-4">
                  <SocialIcons icons={socialIcons} />
                </div>

                <div className="mt-4 text-xs text-gray-500 text-center px-4">
                  By completing registration, you agree to the Earn{" "}
                  <a href="/terms" className="text-blue-600 underline">Terms of Service</a>{" "}
                  and may receive occasional emails from us. Please read our{" "}
                  <a href="/privacy" className="text-blue-600 underline">Privacy Policy</a>{" "}
                  to learn how we use your data.
                </div>
              </div>

              {/* Confirmation Panel */}
              <div
                className={`
                  absolute inset-0 transition-all duration-500 ease-in-out
                  flex flex-col items-center justify-center h-full
                  ${getTransformClasses("confirmation")}
                `}
              >
                <h1
                  id="confirmation-heading"
                  tabIndex="-1"
                  className="text-2xl md:text-4xl font-bold text-green-600"
                >
                  Account Created Successfully!
                </h1>
                <p className="mt-2 text-sm md:text-base text-gray-700 text-center max-w-md">
                  Your account has been created. Please check your email to verify your account and complete the registration process.
                </p>

                {/* Confirmation Image */}
                <img
                  src={confirmationImage}
                  alt="Confirmation"
                  className="mt-6 w-32 h-32 md:w-48 md:h-48 animate-bounceConfirmation"
                />

                {/* Success Buttons */}
                <div className="mt-6 flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
                  <button
                    onClick={goToSignIn}
                    className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
                  >
                    Go to Sign In
                  </button>
                  <button
                    onClick={onClose}
                    className="px-6 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                  >
                    Close
                  </button>
                  <button
                    onClick={goToSellingOrBuying}
                    className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
                  >
                    Continue
                  </button>
                </div>
              </div>

              {/* Selling or Buying Panel */}
              <div
                className={`
                  absolute inset-0 transition-all duration-500 ease-in-out
                  flex items-center justify-center h-full
                  ${getTransformClasses("sellingBuying")}
                `}
              >
                <SellingOrBuying
                  userData={userData}
                  onNext={handleNextFromSellingOrBuying}
                  onBack={goToConfirmation} // Optional: Add a back button handler if needed
                />
              </div>

              {/* Start Exploring Panel */}
              <div
                className={`
                  absolute inset-0 transition-all duration-500 ease-in-out
                  flex items-center justify-center h-full
                  ${getTransformClasses("startExploring")}
                `}
              >
                <StartExploring
                  userData={userData}
                />
              </div>
            </div>
          </div>
            </div>
          </div>
        </FocusTrap>
      );
    }

// Optional: Add PropTypes if desired
// LoginModal.propTypes = {
//   isOpen: PropTypes.bool.isRequired,
//   onClose: PropTypes.func.isRequired,
// };
