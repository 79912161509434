import React, { useState } from "react";
import { Box, Typography, TextField, Button, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Img1 from "../../asserts/loginimage3.png";
import Img2 from "../../asserts/loginimage4.png";

const ProfileSetup = ({ userData, onNext, onBack }) => {
  const [username, setUsername] = useState(userData.username || "");

  const handleNextClick = () => {
    if (!username.trim()) {
      alert("Please enter a username");
      return;
    }

    onNext({ username: username.trim() });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        bgcolor: "#EAF4E9",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "70vw",
          height: "70vh",
          bgcolor: "white",
          borderRadius: "16px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            flex: 1,
            bgcolor: "#0D3D12",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Box
            component="img"
            src={Img1}
            alt="Profile 1"
            sx={{
              position: "absolute",
              top: "25%",
              left: "15%",
              width: "180px",
              height: "180px",
              borderRadius: "12px",
              objectFit: "cover",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          />
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              top: "48%",
              left: "18%",
              bgcolor: "white",
              px: 1.5,
              py: 0.5,
              borderRadius: "8px",
              fontWeight: "bold",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            Katlego_ruth
          </Typography>

          <Box
            component="img"
            src={Img2}
            alt="Profile 2"
            sx={{
              position: "absolute",
              bottom: "25%",
              right: "15%",
              width: "200px",
              height: "200px",
              borderRadius: "12px",
              objectFit: "cover",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          />
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              bottom: "20%",
              right: "18%",
              bgcolor: "white",
              px: 1.5,
              py: 0.5,
              borderRadius: "8px",
              fontWeight: "bold",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            Katlego_ruth
          </Typography>
        </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            px: 6,
          }}
        >
          <IconButton
            onClick={onBack}
            sx={{
              alignSelf: "flex-start",
              mb: 2,
              color: "#4caf50",
            }}
          >
            <ArrowBackIcon />
          </IconButton>

          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              mb: 2,
              color: "#333",
            }}
          >
            Setting up your profile
          </Typography>
          <Typography
            variant="body2"
            sx={{
              mb: 4,
              color: "#666",
              textAlign: "center",
            }}
          >
            Choose a unique username that will represent you to others.
          </Typography>

          <TextField
            fullWidth
            label="Choose a username"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{
              mb: 3,
              width: "60%",
              "& .MuiOutlinedInput-input": {
                padding: "10px 14px",
                fontSize: "14px",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
              },
            }}
            inputProps={{
              maxLength: 20,
            }}
          />

          <Button
            variant="contained"
            sx={{
              bgcolor: "#4caf50",
              color: "white",
              textTransform: "none",
              fontWeight: "bold",
              borderRadius: "8px",
              fontSize: "14px",
              px: 3,
              py: 1,
              width: "60%",
              "&:hover": { bgcolor: "#45a049" },
            }}
            onClick={handleNextClick}
          >
            Create my account
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileSetup;
